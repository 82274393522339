<template>
  <div class="mt-6">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card class="pt-8 white rounded-xl">
          <div class="center-logo">
            <router-link to="/">
              <img class="logo-size" :src="domainLogo" alt="" />
            </router-link>
          </div>
          <v-toolbar
            v-if="recording.title"
            class="font-weight-bold text-h6 mt-n5 ml-4 d-flex justify-center"
            flat
          >
            {{ recording.title }}
          </v-toolbar>
          <div style="position: relative" class="video-frame">
            <div
              v-if="interaction === 'email_capture' && showNow"
              class="white--text interaction-container"
            >
              <h3 class="mb-4">
                {{
                  video_settings["upper_text"] ||
                  "Enter your email address to watch this video"
                }}
              </h3>
              <v-row class="text-left">
                <v-col
                  v-if="video_settings['form_fields']['name'] === 'yes'"
                  cols="12"
                  md="6"
                >
                  <label>First Name</label>
                  <v-text-field
                    class="py-0"
                    v-model="form['first_name']"
                    dense
                    outlined
                    background-color="white"
                    type="text"
                    style="height: 44px"
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="video_settings['form_fields']['name'] === 'yes'"
                  cols="12"
                  md="6"
                >
                  <label>Last Name</label>
                  <v-text-field
                    class="py-0"
                    v-model="form['last_name']"
                    dense
                    outlined
                    background-color="white"
                    type="text"
                    style="height: 44px"
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="video_settings['form_fields']['email'] === 'yes'"
                  cols="12 my-0 py-0 mb-4"
                >
                  <label>Email Address</label>
                  <v-text-field
                    class="py-0"
                    v-model="form.email"
                    dense
                    outlined
                    background-color="white"
                    type="email"
                    style="height: 44px"
                  ></v-text-field>
                </v-col>
                <v-row>
                  <v-col cols="12 text-center my-0 py-0">
                    <v-btn
                      class="capitalize primary rounded-xl"
                      :loading="loading"
                      @click="playVideo"
                    >
                      {{
                        recording.video_settings.email_capture_button_text ||
                        "Play video"
                      }}
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="video_settings['lower_text'].length"
                    cols="10 text-center pt-1"
                    offset="1"
                  >
                    <div>{{ lowerText }}</div>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      v-if="video_settings['allow_skip'] === 'yes'"
                      class="white--text capitalize font-weight-bold"
                      text
                      style="position: absolute; bottom: 0; right: 35px"
                      @click="skip"
                    >
                      Skip
                    </v-btn>
                  </v-col>
                </v-row>
              </v-row>
            </div>
            <div
              v-if="interaction === 'call_to_action' && showNow"
              class="white--text interaction-container"
            >
              <v-row style="position: relative; height: 100%">
                <v-spacer></v-spacer>
                <v-col class="download-btn">
                  <v-btn
                    text
                    color="white"
                    class="capitalize font-weight-bold text-h4"
                    :loading="loading"
                    download
                    @click="openLink(recording)"
                  >
                    {{
                      recording.video_settings.cta_button_text ||
                      "Download Now!"
                    }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-col>
                <v-btn
                  v-if="video_settings['allow_skip'] === 'yes'"
                  class="white--text capitalize font-weight-bold"
                  text
                  style="position: absolute; bottom: 25px; right: 35px"
                  @click="skip"
                >
                  Skip
                </v-btn>
              </v-row>
            </div>
            <div class="video-container">
              <video
                class="video-content video-js vjs-default-skin"
                playsinline
                ref="video"
                id="video"
                controlsList="nodownload"
                oncontextmenu="return false"
              ></video>
            </div>
          </div>
          <v-card-text
            v-if="recording.message"
            class="font-weight-bold message-container"
          >
            {{ recording.message }}
          </v-card-text>
          <v-card-actions class="pa-10">
            <!--            <v-spacer></v-spacer>-->
            <!--            <v-btn-->
            <!--              to="/sign_in"-->
            <!--              class="secondary black&#45;&#45;text capitalize rounded-xl px-10"-->
            <!--              large-->
            <!--              text-->
            <!--            >-->
            <!--              Login-->
            <!--            </v-btn>-->
            <!--            <v-btn-->
            <!--              to="/sign_up"-->
            <!--              class="primary capitalize rounded-xl ml-6 px-10"-->
            <!--              large-->
            <!--              text-->
            <!--            >-->
            <!--              Register-->
            <!--            </v-btn>-->
            <!--            <v-spacer></v-spacer>-->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
  name: "ShareRecording",
  components: {},
  data() {
    return {
      recording: {},
      interaction: "",
      form: {},
      loading: false,
      video_settings: {},
      interval: null,
      duration: 0,
      currentTime: 0,
      showNow: false,
      timer: "",
      domainLogo: null,
    };
  },
  computed: {
    lowerText() {
      let text = this.video_settings["lower_text"];
      if (text.length > 70) {
        text = text.substr(0, 70) + "...";
      }
      return text;
    },
  },
  created() {
    this.getVideoUrl();
  },
  mounted() {
    this.$refs.video.addEventListener("play", () => {
      this.play();
    });
  },
  methods: {
    ...mapActions({
      add_contact: "contacts/add_contact",
    }),
    async getVideoUrl() {
      try {
        const response = await this.$axios.get(
          "/recordings/play-video/" + this.$route.params.id
        );
        this.recording = response.data;
        const customDomainsDtls = response.data.user["custom_domains"];

        // default domain config
        const defaultDomain = customDomainsDtls.find(
          (domain) => domain.default === "yes"
        );
        if (defaultDomain) {
          this.domainLogo = defaultDomain.domain_logo_url;
          localStorage.setItem("favicon", defaultDomain.favicon_url);
          localStorage.setItem("tagline", defaultDomain.tagline);
          localStorage.setItem("domain_logo", defaultDomain.domain_logo_url);
          let checkDefault = defaultDomain.tagline !== undefined ? true : false;
          localStorage.setItem("is_custom_domain", checkDefault);
        } else {
          this.domainLogo = require("../../assets/logo.png");
          localStorage.removeItem("favicon");
          localStorage.removeItem("tagline");
          localStorage.removeItem("domain_logo");
          localStorage.setItem("is_custom_domain", false);
        }

        const options = {
          controls: true,
          autoplay: false,
          preload: "auto",
          fluid: true,
          aspectRatio: "15:8",
          sources: [
            {
              src: response.data.video_url,
              type: "video/webm",
            },
          ],
        };
        // Create Video.js player
        videojs(this.$refs.video, options);

        this.logo = response.data?.user?.settings?.logo_url;
        if (response.data.video_settings !== null) {
          this.video_settings = response.data.video_settings;
          this.interaction = this.video_settings["interaction_type"];
          this.emailCaptureInteraction();
          if (this.video_settings.timer) {
            const time = this.video_settings.timer;
            const a = time.split(":"); // split it at the colons
            // minutes are worth 60 seconds. Hours are worth 60 minutes.
            this.timer = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
          }
        }
      } catch (e) {
        this.$toast.error("Error fetching video, please try again");
      }
    },
    emailCaptureInteraction() {
      if (this.video_settings["appearance"] === "before_video") {
        this.showNow = true;
      }
    },
    play() {
      if (this.video_settings["appearance"] === "before_video") {
        this.showNow = true;
      }
      this.$refs.video.play();
      this.interval = setInterval(() => {
        this.currentTime = this.$refs.video.currentTime;
        this.duration = this.$refs.video.duration;
        if (
          this.video_settings["appearance"] === "during_video" &&
          this.currentTime >= this.timer
        ) {
          if (!this.showNow) {
            this.showNow = true;
            this.$refs.video.pause();
            clearInterval(this.interval);
          }
        }
        if (this.currentTime >= this.duration) {
          if (this.video_settings["appearance"] === "after_video") {
            this.showNow = true;
          }
          clearInterval(this.interval);
        }
      }, 1000);
    },
    skip() {
      this.interaction = "skip";
      this.play();
    },
    async playVideo() {
      this.loading = true;
      this.form.user_id = this.recording.user_id;
      try {
        const response = await this.add_contact(this.form);
        this.loading = false;
        this.$response.sendSuccess(response);
        this.interaction = "play_video";
        setTimeout(() => {
          this.play();
        }, 500);
      } catch (e) {
        this.$response.sendError(e);
        this.loading = false;
      }
    },
    async openLink(recording) {
      const video_settings = recording.video_settings;
      if (video_settings["open_link_in_new_tab"]) {
        window.open(video_settings["redirect_to"]);
      } else {
        window.location.href = video_settings["redirect_to"];
      }
      this.interaction = video_settings["cta_button_text"];
      this.play();
    },
  },
  // beforeCreate() {
  //   this.getVideoUrl();
  // },
};
</script>

<style scoped>
.interaction-container {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100% !important;
  bottom: 0px;
  height: 53vh !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 99999999999;
  padding: 10px 40px;
}
.download-btn {
  position: absolute;
  top: 45%;
  color: #ffffff !important;
}
.video-frame {
  margin: 0px 40px !important;
}
.video-container {
  min-height: 53vh !important;
  width: 100%;
  overflow: hidden;
  border: none !important;
  background-color: transparent !important;
  background-color: #222 !important;
}
.video-content video {
  width: 100%;
  background-color: #222 !important;
  border-radius: 10px !important;
}
.message-container {
  text-align: left !important;
  padding: 0px 40px !important;
}
.logo-size {
  height: 50px;
  width: 150px;
  margin-bottom: 20px;
}
</style>
